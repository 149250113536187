.part2Content {
  position: relative;
}

.blurOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.payButton {
  position: relative; /* Ensure the button stays above the overlay */
  z-index: 2;
}

.centeredDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
}

.payNowButton {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.disabledButton {
  background-color: #4caf50;
  cursor: not-allowed;
  opacity: 0.7;
  pointer-events: none;
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  border: none;
  border-radius: 5px;
}

.helperText {
  font-size: 16px;
  color: white;
  margin-top: 10px;
}

.cover {
  height: 150px;
  padding: 30px 7vw;
  background-image: url("../image/cover.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.coverText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
}

.title {
  font-size: 40px;
  color: #6d6d6d;
}

.route {
  font-size: 20px;
  color: #6f6d6d;
}

.filterOp {
  width: fit-content;
  height: 54px;
  background: white;
  outline: none;
  color: #6d6d6d;
  border: 1px solid #6d6d6d;
  padding: 14px 24px;
  font-size: 16px;
  margin-right: 14px;
}

.contentSec {
  padding: 0 7vw;
  margin-top: 78px;
}

.authorDp {
  width: 77px;
  height: 77px;
  border: 0.3px solid #6d6d6d;
  margin-right: 18px;
}

.part1 {
  display: flex;
  height: 100%;
  position: relative;
}

.productData {
  width: 40%;
  padding: 20px 65px;
  background: #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner {
  width: 60%;
  height: 500px;
}

.productTitle {
  font-size: 32px;
  color: #484848;
  margin: 8px 0;
}

.productDes {
  margin-top: 8px;
  margin-bottom: 37px;
  font-size: 18px;
  color: #6f6d6d;
}

.authorSec {
  display: flex;
  margin-bottom: 39px;
}

.authorName {
  font-size: 20px;
  color: #6d6d6d;
}

.part2 {
  background: #353535;
  padding: 58px 65px;
  margin-top: 40px;
}

.p2Title {
  font-size: 36px;
  color: #ffffff;
  margin-bottom: 24px;
}

.p2Text {
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 50px;
}

.pdfCon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdfBtnCon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdfBtn {
  font-size: 30px;
  color: white;
  margin-right: 10px;
  margin-top: 20px;
}

.sliderImg {
  height: 1000px;
}

.videoPlayerCon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox_container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.checkbox_container input[type="checkbox"] {
  margin-right: 8px;
}

.links {
  cursor: pointer;
  text-decoration: none;
  color: rgb(67, 67, 163);
}

.htmlContent {
  color: white;
  max-width: 794px;
  margin: 0 auto;
}

.audioSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.audioContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.seekerContainer {
  display: flex;
  flex-direction: column;
  align-items: end;
  color: white;
  width: 100%;
}

.audioIcons {
  font-size: 45px;
  color: white;
  cursor: pointer;
}

.audioBtn {
  text-decoration: none;
  border: none;
  background-color: transparent;
}

.audioBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
  margin: 20px auto;
}

.audioThumbnail {
  height: 300px;
}

.unlike {
  font-size: 25px;
  cursor: pointer;
}

.like {
  font-size: 25px;
  cursor: pointer;
  color: red;
}

.likeContainer {
  position: absolute;
  top: 20px;
  left: 20px;
}

/* Add these media queries at the end of your CSS file */

@media screen and (max-width: 768px) {
  .contentBox {
    width: 95%;
    margin: 0 auto;
  }

  .part1 {
    flex-direction: column;
    gap: 20px;
  }

  .productData {
    width: 100%;
  }

  .banner {
    width: 100%;
    height: auto;
  }

  .productTitle {
    font-size: 24px;
  }

  .productDes {
    font-size: 16px;
  }

  /* PDF viewer responsiveness */
  .pdfCon {
    width: 100%;
    overflow-x: auto;
  }

  /* Audio player responsiveness */
  .audioSection {
    width: 100%;
  }

  .audioThumbnail {
    width: 100%;
    height: auto;
  }

  .audioContainer {
    width: 100%;
    padding: 10px;
  }

  /* Video player responsiveness */
  .videoPlayerCon video {
    width: 100%;
    height: auto;
  }

  /* Slider responsiveness */
  .sliderImg {
    width: 100%;
    height: auto;
  }

  /* Payment section responsiveness */
  .helperText {
    font-size: 14px;
    padding: 10px;
  }

  .payNowButton,
  .disabledButton {
    width: 80%;
    max-width: 300px;
  }
}

@media screen and (max-width: 480px) {
  .cover {
    padding: 20px;
  }

  .title {
    font-size: 20px;
  }

  .route {
    font-size: 14px;
  }

  .authorName {
    font-size: 16px;
  }

  .audioBtnContainer {
    gap: 10px;
  }

  .audioBtn {
    padding: 8px;
  }

  .audioIcons {
    font-size: 18px;
  }

  /* PDF controls */
  .pdfBtnCon {
    gap: 20px;
  }

  .pdfBtn {
    font-size: 24px;
  }
}

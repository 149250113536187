.cover_container {
  position: relative;
  width: 100%;
  height: 587px;
  z-index: 1;
  overflow: hidden;
}

.cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.coverImg {
  height: 585px;
  width: 35%;
  position: absolute;
  top: 0;
  right: 100px;
  z-index: 2;
}

.text_area {
  position: absolute;
  top: 119px;
  left: 7vw;
  height: auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  color: #6b6b6b;
  z-index: 2;
}

.title {
  font-size: 54px;
  margin-bottom: 10px;
}

.des {
  font-size: 20px;
  width: 100%;
  line-height: 1.5;
}

/* Tablet Breakpoint */
@media screen and (max-width: 1024px) {
  .text_area {
    width: 60%;
  }

  .title {
    font-size: 42px;
  }

  .coverImg {
    width: 40%;
    right: 50px;
  }

  .des {
    font-size: 18px;
  }
}

/* Mobile Breakpoint */
@media screen and (max-width: 768px) {
  .cover_container {
    height: auto;
    min-height: 80vh;
    padding: 0px 0;
  }

  .cover {
    height: 100%;
    position: absolute;
    top: 0;
  }

  .text_area {
    position: relative;
    top: 0;
    left: 0;
    width: 90%;
    margin: 20px auto;
    text-align: center;
  }

  .coverImg {
    position: relative;
    width: 80%;
    height: auto;
    max-height: 400px;
    right: auto;
    margin: 20px auto;
    display: block;
    object-fit: contain;
  }

  .des {
    width: 100%;
    font-size: 18px;
    padding: 0 10px;
  }
}

/* Small Mobile Breakpoint */
@media screen and (max-width: 480px) {
  .cover_container {
    min-height: 70vh;
  }

  .coverImg {
    max-height: 300px;
  }
}

.ad_section {
  margin: 56px 0;
  padding: 0px 7vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.ad1 {
  width: 35vw;
  height: 13.6vw;
}

@media screen and (max-width: 768px) {
  .ad_section {
    flex-direction: column;
  }

  .ad1 {
    width: 90vw;
    height: auto;
    margin: 10px 0;
  }
}

.content_container {
  padding: 0px 7vw;
}

.about {
  padding: 20px 7vw;
  margin-top: -50px;
  background-color: #ffff;
  text-align: center;
  color: #6d6d6d;
}

.about_title {
  font-size: 44px;
  padding-top: 58px;
  margin-bottom: 3px;
}

.hr {
  width: 80%;
  max-width: 339px;
  margin: 20px auto;
}

.about_des {
  font-size: 20px;
  line-height: 160%;
  text-align: center;
  color: #6d6d6d;
  margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .about_title {
    font-size: 32px;
  }

  .about_des {
    font-size: 16px;
    padding: 0 20px;
  }
}

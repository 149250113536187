.container {
    display: flex;
    justify-content: center;
  }
  
  .container2 {
    /* background-color: red; */
    width: 100%;
    margin: 100px 0px;
    color: #6f6d6d;
  }
  
  .title {
    font-size: 20px;
  }
  
  .formTitle {
    font-size: 16px;
    color: #6f6d6d;
    margin-bottom: 9px;
    margin-top: 16px;
  }
  
  .inputText {
    width: 100%;
    height: 36px;
    border: 1px solid #6f6d6d;
    border-radius: 2px;
    outline: none;
    padding: 12px 20px;
    background-color: #eae9e9;
  }
  
  .errorTxt {
    font-size: 12px;
    color: red;
  }
  
  .passCon {
    margin-bottom: 20px;
  }

  .btn{
    border: none;
    margin: 20px 0px;
  }
  
.wdList {
  min-height: 500px;
}

.listCardCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #6f6d6d;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  height: 70px;
}

.listCardConCS {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #6f6d6d;
  margin-bottom: 10px;
  padding: 12px 15px;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  height: fit-content;
}

.listText {
  font-size: 20px;
}

.infoConCS {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.firstCon {
  margin-bottom: 50px;
}

.status {
  color: rgb(112, 199, 112);
}

.pending {
  color: rgb(227, 167, 167);
}

.wdBtn {
  margin-top: 20px;
  min-width: 190px;
  max-width: 300px;
}

.inputText {
  width: 100%;
  height: 36px;
  border: 1px solid #6f6d6d;
  border-radius: 2px;
  outline: none;
  padding: 12px 20px;
  background-color: #eae9e9;
}

.error {
  font-size: 14px;
  color: red;
}

.confirmBtn {
  margin-top: 20px;
}

.payDet {
  margin-top: 50px;
  margin-bottom: 20px;
  border: 1px solid #6f6d6d;
  padding: 10px 20px;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.btnCon {
  margin-right: 10px;
}

.bnTitle {
  margin: 10px 0px;
}

@media screen and (max-width: 680px) {
  .wdBtn {
    margin-top: 20px;
    min-width: 190px;
    max-width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .buttons {
    justify-content: space-between;
    flex-direction: column;
  }
}
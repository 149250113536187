.container {
  margin-bottom: 150px;
}

.titleCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 44px;
  color: #6d6d6d;
}

.view_all {
  font-size: 18px;
  cursor: pointer;
  color: #6d6d6d;
}

.carausel_section {
  
  height: 650px;
  background: #ffffff;
  padding-top: 50px;
}

@media screen and (max-width: 680px) {
  .content_container {
    padding: 0px 8vw;
  }
  
  .media_content_container {
    padding: 0px 7vw;
    margin-top: 200px;
  }
  .carausel_section {
  
    height: 630px;
    background: #ffffff;
    padding-top: 50px;
  }
}

.link {
  text-decoration: none;
}

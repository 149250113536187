.cw_text {
  font-size: 20px;
}

.nav_container {
  background-color: #ffffff;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo_container p {
  font-size: 28px;
  margin: 0px 24px 0px 7px;
  font-family: "Prata", serif;
  color: #6d6d6d;
}

.logo {
  width: 22px;
}

.links {
  display: flex;
  align-items: center;
  z-index: 3;
}
.nav_container .toggleMenu {
  display: none;
}

@media screen and (max-width: 1200px) {
  .links {
    position: absolute;
    flex-direction: column;
    width: 95%;
    left: 50%;
    top: 180px;
    transform: translateX(-50%);
    background-color: white;
    border-radius: 8px;
    padding: 0px;
    overflow: hidden;
    max-height: 0px;
    transition: max-height 0.3s ease-out;
  }
  .nav_container {
    justify-content: space-between;
    padding: 0px 30px;
    box-sizing: border-box;
  }
  .nav_container .toggleMenu {
    display: block;
  }
}

.nav_link {
  font-size: 14px;
  margin: 0px 24px;
  color: #6f6d6d;
  font-weight: 700;
  cursor: pointer;
  padding: 25px 0;
  position: relative;
}

.nav_link:hover {
  border-bottom: 2px solid #6d6d6d;
}

.lit {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropDown {
  position: absolute;
  top: 69px;
}
@media screen and (max-width: 1200px) {
  .dropDown {
    position: unset;
  }
}

.link {
  text-decoration: none;
}

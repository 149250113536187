.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 175px;
  height: 46px;
  background-color: #f8f8f8;
  transition: 0.5s ease;
  cursor: pointer;
}

.btn:hover {
  background-color: #393939;
}

.btn:hover .icon,
.btn:hover .text {
  color: #ffffff;
}

.icon {
  color: #6d6d6d;
  margin-right: 8px;
  transition: 0.5s ease;
}

.text {
  font-size: 20px;
  color: #6d6d6d;
  transition: 0.5s ease;
}

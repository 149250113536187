.btn {
  background: #6f6d6d;
  border: 0.6px solid #6d6d6d;
  border-radius: 2px;
  padding: 7px 18px;
  cursor: pointer;
  transition: 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn:hover {
  background: #535252;
}

.btn p {
  color: #ffff;
}

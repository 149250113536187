.container {
  width: 143px;
  height: fit-content;
  background: #ffffff;
  border: 0.6px solid #6d6d6d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item {
  width: 100%;
  height: 40px;
  font-weight: 700;
  font-size: 14px;
  color: #6f6d6d;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s ease;
}

.item:hover {
  background-color: #6d6d6d;
  color: #ffffff;
}

.link {
  text-decoration: none;
}

.btn {
  border: 1px solid #6d6d6d;
  font-weight: 400;
  font-size: 20px;
  color: #6d6d6d;
  width: 224px;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 38px;
  cursor: pointer;
  transition: 0.5s ease;
}

.btn:hover {
  border: 1px solid #ffffff;
  background-color: #6d6d6d;
  color: #ffff;
}
